import React from "react"
import Navlink from "../components/layout/navlink"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>GJ Cycling - documentation graphique</h1>
    <p>Dans le cadre de l’élaboration de votre identité graphique et supports de communication, nous vous mettons à disposition une plateforme de gestion de votre design system basée sur le cloud. Sur cette plateforme vous y retrouverez vos règles d’usages des couleurs, des typographies, du logo, des ses déclinaisons, ses règles d’utilisation ou encore de ses champs d’application.</p>
    <p>Nous centralisons ici l’ensemble de vos fichiers de votre charte graphique téléchargeable, vos matrices graphiques éditables,...</p>
    <aside>
      <h2>Sommaire</h2>
      <Navlink />
    </aside>
  </Layout>
)

export default IndexPage
